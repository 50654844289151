































import { Component, mixins, Watch } from 'nuxt-property-decorator';
import { FocusTrap } from 'focus-trap-vue';
import { onKeyStroke } from '@vueuse/core';

import { usePageSearch } from '@/composables/page-search';

import { state as navState, mutations as navMutations } from '@/components/navigation/NavigationState';

import LockBodyScrollMixin from '@/mixins/lock-body-scroll';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    FocusTrap,
  },
})
export default class SiteSearch extends mixins(LockBodyScrollMixin, ComposableMixin(usePageSearch)) {
  focusIsTrapped = false;

  get navState () { return navState }

  mounted () {
    onKeyStroke('Escape', this.closeSearch);

    if (this.pageSearchOpen)
      this.onOpen();
  }

  closeSearch () {
    this.pageSearchOpen = false;
  }

  @Watch('pageSearchOpen')
  whenOpenChanges (open) {
    open ? this.onOpen() : this.onClose();
  }

  @Watch('$route.path')
  onRouteChange () {
    setTimeout(this.closeSearch, 250);
  }

  @Watch('navState.mobileNavOpen')
  @Watch('navState.secondaryNavOpen')
  onStateChange (newVal) {
    if (newVal)
      setTimeout(this.closeSearch, 100);
  }

  onOpen () {
    this.focusIsTrapped = true;
    this.lockScroll(true);
    document.body.classList.add('-site-search-is-open');
    navMutations.closeMobileNav();
    navMutations.closeSecondaryNav();
  }

  onClose () {
    this.focusIsTrapped = false;
    this.lockScroll(false);
    document.body.classList.remove('-site-search-is-open');
  }
}
