
























import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class LoadingSpinner extends Vue {
  radius = 33;
}
