















import { Component, Vue } from 'nuxt-property-decorator';
import { computed } from '@nuxtjs/composition-api';

import { useKeyValueStore } from '@/composables/key-value-store';

import { IconSocialFacebook, IconSocialInstagram, IconSocialYoutube, IconSocialSpotify } from '@/icons';

interface SocialMediaLink {
  title: string
  icon: any
  url?: string
}

@Component({
  components: {
    IconSocialFacebook,
    IconSocialInstagram,
    IconSocialYoutube,
    IconSocialSpotify,
  },
  setup () {
    const facebookUrl = useKeyValueStore('facebook_url');
    const instagramUrl = useKeyValueStore('instagram_url');
    const youtubeUrl = useKeyValueStore('youtube_url');
    const spotifyUrl = useKeyValueStore('spotify_url');

    const links = computed<SocialMediaLink[]>(() => [
      {
        title: 'Facebook',
        url: facebookUrl.value,
        icon: IconSocialFacebook,
      },
      {
        title: 'Instagram',
        url: instagramUrl.value,
        icon: IconSocialInstagram,
      },
      {
        title: 'Youtube',
        url: youtubeUrl.value,
        icon: IconSocialYoutube,
      },
      {
        title: 'Spotify',
        url: spotifyUrl.value,
        icon: IconSocialSpotify,
      },
    ].filter(link => link.url));

    return {
      links,
    };
  },
})
export default class SocialMediaLinks extends Vue {
  links!: SocialMediaLink[];
}
