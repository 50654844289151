










































import { Component, Vue, Prop } from 'nuxt-property-decorator';

import type { NavigationLinkProps } from '../NavigationState';

import { IconPlus } from '@/icons';

@Component({
  components: {
    IconPlus,
  },
})
export default class MobileNavigationItem extends Vue {
  @Prop({ type: Object, required: true })
  link!: NavigationLinkProps;

  @Prop({ type: Number, required: true })
  index!: number;

  subNavigationOpen = false;

  toggleSubNavigation () {
    this.subNavigationOpen = !this.subNavigationOpen;
  }
}
