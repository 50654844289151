










import { Component, mixins } from 'nuxt-property-decorator';

import { usePageSearch } from '@/composables/page-search';

import { IconClose } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconClose,
  },
})
export default class SiteSearchClose extends mixins(ComposableMixin(usePageSearch)) {
  closeSearch () {
    this.pageSearchOpen = false;
  }
}
