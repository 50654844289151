var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,_vm._b({directives:[{name:"autolink",rawName:"v-autolink",value:(true),expression:"true"}],tag:"component",staticClass:"button",class:[
    _vm.sizeClass,
    ( _obj = {}, _obj[("-" + _vm.variant)] = _vm.variant, _obj ),
    { '-block' : _vm.block },
    { '-outline' : _vm.outline },
    { '-disabled' : _vm.disabled } ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'component',_vm.buttonProps,false),[_vm._t("left-icon"),_vm._v(" "),(_vm.component === 'nuxt-link')?_vm._t("default"):_c('span',{staticClass:"button_content"},[_vm._t("default")],2),_vm._v(" "),_vm._t("right-icon")],2)}
var staticRenderFns = []

export { render, staticRenderFns }