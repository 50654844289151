























import { Component, mixins } from 'nuxt-property-decorator';

import { usePageSearch } from '@/composables/page-search';

import { ComposableMixin } from '@/mixins/composable';

@Component
export default class SiteSearchResult extends mixins(ComposableMixin(usePageSearch)) {
  get categories () {
    return this.pageSearchResult.filter(category => category.result.length > 0);
  }
}
