import Vue from 'vue';

interface SiteRevealState {
  siteIsReady: boolean,
}

export const state: SiteRevealState = Vue.observable({
  siteIsReady: false,
});

export const mutations = {
  setSiteReadyState (ready: boolean) { state.siteIsReady = ready },
};
