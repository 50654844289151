import { defineAsyncComponent } from '@nuxtjs/composition-api';

// List of implemented blocks
export const Article = defineAsyncComponent(() => import('./blocks/Article.vue'));
export const Image = defineAsyncComponent(() => import('./blocks/Image.vue'));
export const Audio = defineAsyncComponent(() => import('./blocks/Audio.vue'));
export const File = defineAsyncComponent(() => import('./blocks/File.vue'));
export const ArticleList = defineAsyncComponent(() => import('./blocks/ArticleList.vue'));
export const Faq = defineAsyncComponent(() => import('./blocks/Faq.vue'));
export const NewsList = defineAsyncComponent(() => import('./blocks/NewsList.vue'));
export const MailchimpSubscribe = defineAsyncComponent(() => import('./blocks/MailchimpSubscribe.vue'));
export const MarketHypeSubscribe = defineAsyncComponent(() => import('./blocks/MarketHypeSubscribe.vue'));
export const Wysiwyg = defineAsyncComponent(() => import('./blocks/Wysiwyg.vue'));
export const WysiwygTwoColumn = defineAsyncComponent(() => import('./blocks/WysiwygTwoColumn.vue'));
export const ScenkonstSpotlight = defineAsyncComponent(() => import('./blocks/ScenkonstSpotlight.vue'));
export const ScenkonstOnStage = defineAsyncComponent(() => import('./blocks/ScenkonstOnStage.vue'));
export const ScenkonstInlineTerm = defineAsyncComponent(() => import('./blocks/ScenkonstInlineTerm.vue'));
export const ScenkonstDigitalStage = defineAsyncComponent(() => import('./blocks/ScenkonstDigitalStage.vue'));
export const ScenkonstCalendar = defineAsyncComponent(() => import('./blocks/ScenkonstCalendar.vue'));
export const ImageGallery = defineAsyncComponent(() => import('./blocks/ImageGallery.vue'));
export const Video = defineAsyncComponent(() => import('./blocks/Video.vue'));
export const FormBlock = defineAsyncComponent(() => import('./blocks/FormBlock.vue'));
export const ScenkonstSponsor = defineAsyncComponent(() => import('./blocks/ScenkonstSponsor.vue'));
export const ScenkonstSubscriptionsList = defineAsyncComponent(() => import('./blocks/ScenkonstSubscriptionsList.vue'));
