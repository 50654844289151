


















import { Component, mixins, Watch } from 'nuxt-property-decorator';

import { usePageSearch } from '@/composables/page-search';

import { IconSearch } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconSearch,
  },
})
export default class SiteSearchInput extends mixins(ComposableMixin(usePageSearch)) {
  @Watch('pageSearchOpen')
  whenOpenChanges (open) {
    if (open)
      (this.$refs.input as HTMLInputElement).focus();
  }
}
