































import { Component, Vue } from 'nuxt-property-decorator';

import { NavigationLinkProps, usePageNavigation } from '@/composables/page-navigation';

@Component({
  setup: () => usePageNavigation(),
})
export default class SiteFooterPrimaryNav extends Vue {
  primaryLinks!: NavigationLinkProps[];
}
