







import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class ScenkonstSite extends Vue {
  get title () {
    return this.$config.siteName;
  }
}
