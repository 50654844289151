
































import { mixins, Component, Watch } from 'nuxt-property-decorator';
import { onKeyStroke } from '@vueuse/core';

import { usePageNavigation, NavigationLinkProps } from '@/composables/page-navigation';

import { state as siteAlertState } from '@/components/SiteAlert/SiteAlertState';

import { state, mutations } from '../NavigationState';

import LockBodyScrollMixin from '@/mixins/lock-body-scroll';

@Component({
  setup: () => usePageNavigation(),
})
export default class MobileNav extends mixins(LockBodyScrollMixin) {
  primaryLinks!: NavigationLinkProps[];
  secondaryLinks!: NavigationLinkProps[];

  get mobileNavOpen () {
    return state.mobileNavOpen;
  }

  get siteAlertMarginPush () {
    return `margin-top: ${siteAlertState.height}px`;
  }

  mounted () {
    onKeyStroke('Escape', this.closeMobileNav);
  }

  closeMobileNav () {
    mutations.closeMobileNav();
  }

  @Watch('$route')
  onRouteChange () {
    setTimeout(this.closeMobileNav, 250);
  }

  @Watch('mobileNavOpen')
  whenOpenStateChanges (mobileNavOpen) {
    if (mobileNavOpen) {
      document.body.classList.add('-mobile-nav-is-open');
      this.lockScroll(true);
    } else {
      document.body.classList.remove('-mobile-nav-is-open');
      this.lockScroll(false);
    }
  }
}
