



















import { Component, mixins } from 'nuxt-property-decorator';

import { usePageSearch } from '@/composables/page-search';

import { ComposableMixin } from '@/mixins/composable';

@Component
export default class SiteSearchNav extends mixins(ComposableMixin(usePageSearch)) {
  get active () {
    return this.pageSearchResult.some(category => category.result.length > 0);
  }
}
