



































import { Component, mixins } from 'nuxt-property-decorator';

import { useGlobalAlert } from '@/composables/global-alert';

import { state, mutations } from './SiteAlertState';

import { IconArrowRight } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class SiteAlert extends mixins(
  ComposableMixin(useGlobalAlert),
) {
  resizeObserver;

  get alert () {
    return state;
  }

  get modalOpen () {
    return this.alert.modal.isPresent && !this.alert.modal.isDismissed;
  }

  mounted () {
    if (!this.globalAlertLoading && this.globalAlert.message) {
      this.getSiteAlertHeight();
      this.resizeObserver = new ResizeObserver(this.getSiteAlertHeight);
      this.resizeObserver.observe(this.$refs.container as HTMLElement);
    }
  }

  beforeDestroy () {
    if (!this.globalAlertLoading && this.globalAlert.message)
      this.resizeObserver.unobserve(this.$refs.container as HTMLElement);
  }

  dismissModal () {
    mutations.dismissModal();
  }

  getSiteAlertHeight () {
    mutations.setSiteAlertHeight((this.$refs.container as HTMLElement).clientHeight);
  }
}
