import { render, staticRenderFns } from "./ArticleLink.vue?vue&type=template&id=2557e0f5&"
import script from "./ArticleLink.vue?vue&type=script&lang=ts&"
export * from "./ArticleLink.vue?vue&type=script&lang=ts&"
import style0 from "./ArticleLink.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdaptiveImageNew: require('/tmp/build_601978b3/src/components/media/AdaptiveImageNew.vue').default,NuxtLinkExternal: require('/tmp/build_601978b3/src/components/misc/NuxtLinkExternal.vue').default,Button: require('/tmp/build_601978b3/src/components/button/Button.vue').default,ButtonCircle: require('/tmp/build_601978b3/src/components/button/Circle.vue').default})
