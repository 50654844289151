






















import { Component, Vue, Watch } from 'nuxt-property-decorator';

import { usePageNavigation, NavigationLinkProps } from '@/composables/page-navigation';

import { state, mutations } from '../NavigationState';

@Component({
  setup: () => usePageNavigation(),
})
export default class SiteHeaderSecondaryNav extends Vue {
  secondaryLinks!: NavigationLinkProps[];

  get secondaryNavOpen () {
    return state.secondaryNavOpen;
  }

  closeSecondaryNav () {
    mutations.closeSecondaryNav();
  }

  @Watch('$route')
  onRouteChange () {
    setTimeout(this.closeSecondaryNav, 250);
  }
}
