/**
 * Use contrast checker to set the --current-color-inverted
 * css variable (based on active text color)
 * https://wunnle.com/dynamic-text-color-based-on-background
 */

function getRGB (c: any) {
  return parseInt(c, 16) || c;
}

function getsRGB (c: any) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance (hexColor: string) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}

function getContrast (f: string, b: string) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

// https://stackoverflow.com/a/3627747
const rgb2hex = (rgb: string) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1)
  .map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;

// https://stackoverflow.com/a/44134328
function hsl2hex (hsl: string) {
  // eslint-disable-next-line prefer-const
  let [h, s, l] = hsl.match(/^hsl\((\d+),\s*(\d+),\s*(\d+)%\)$/).slice(1).map(n => parseInt(n, 10));
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

/**
 * Text contrast color (black or white) based on background color
 * @param bgColor Background color
 */
export function getContrastTextColor (bgColor: string) {
  if (!bgColor)
    return null;

  let hexColor = bgColor;
  if (bgColor.startsWith('rgb')) hexColor = rgb2hex(bgColor);
  if (bgColor.startsWith('hsl')) hexColor = hsl2hex(bgColor);

  const whiteContrast = getContrast(hexColor, '#ffffff');
  const blackContrast = getContrast(hexColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}
