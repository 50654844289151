














import { Component, Vue } from 'nuxt-property-decorator';

import { state, mutations } from '../NavigationState';

import { IconClose, IconHamburger } from '@/icons';

@Component({
  components: {
    IconClose,
    IconHamburger,
  },
})
export default class MobileNavigationToggle extends Vue {
  get mobileNavOpen () {
    return state.mobileNavOpen;
  }

  toggleMobileNav () {
    mutations.toggleMobileNav();
  }
}
