import Vue from 'vue';

export interface SiteAlertModalProps {
  isPresent: boolean;
  isDismissed: boolean;
  title: string;
  description: string;
}
interface SiteAlertState {
  message: string;
  linkUrl: string;
  height: number;
  modal: SiteAlertModalProps;
}

export const state: SiteAlertState = Vue.observable({
  message: 'Betalningsproblem vid internetköp! Vi hoppas att felet snart ska vara avhjälpt.',
  linkUrl: 'https://google.se',
  height: 0,
  modal: {
    isPresent: true,
    isDismissed: false,
    title: 'Betalningsproblem vid internetköp!',
    description: 'Många upplever just nu betalningsproblem vid internetköp. Vi hoppas att felet snart ska vara avhjälpt.',
  },
});

export const mutations = {
  dismissModal () { state.modal.isDismissed = true },
  setSiteAlertHeight (height: number) { state.height = height },
};
