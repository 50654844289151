



















































import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class ArticleLink extends Vue {
  @Prop({ type: String, required: true })
  to!: string

  @Prop(Object)
  image!: any

  @Prop({ type: String, required: true })
  title!: string

  @Prop(String)
  subtitle!: string

  @Prop(String)
  description!: string

  @Prop(Boolean)
  buttonHorizontal!: boolean

  @Prop({ type: String, required: false })
  linkText!: string
}
