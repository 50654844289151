














import { Component, Vue } from 'nuxt-property-decorator';

import { usePageNavigation, NavigationLinkProps } from '@/composables/page-navigation';

@Component({
  setup: () => usePageNavigation(),
})
export default class SiteFooterPrimaryNav extends Vue {
  secondaryLinks!: NavigationLinkProps[];
}
