














import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class SiteHeaderLink extends Vue {
  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: true })
  href!: string

  @Prop({ type: Boolean, default: false })
  external!: boolean
}
