





import { Component, Vue } from 'nuxt-property-decorator';

import { state, mutations } from './SiteRevealState';

@Component
export default class SiteReveal extends Vue {
  get siteIsReady () {
    return state.siteIsReady;
  }

  mounted () {
    if (!this.siteIsReady)
      if (document.readyState !== 'complete')
        window.addEventListener('load', this.hideSiteRevealer);
      else
        this.hideSiteRevealer();
  }

  hideSiteRevealer () {
    mutations.setSiteReadyState(true);
    window.removeEventListener('load', this.hideSiteRevealer);
  }
}
