




























import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';

import 'lazysizes';
import { graphQLImage } from '@/composables/image-graphql';

// Not used
interface ImageSize {
  url: string,
  width: number,
}

// Not used
export interface AdaptiveImageProps {
  alt: string | null,
  dominantColor: string,
  dimensions: {
    width: number,
    height: number,
  },
  srcset: ImageSize[],
  downloadable?: boolean,
}

@Component
export default class AdaptiveImageNew extends Vue {
  @Prop({ type: Object, required: true })
  image!: any;

  @Prop({ type: String })
  fallbackAlt!: string;

  @Prop({ type: Boolean, default: true })
  load!: boolean;

  loaded = false;
  resetImage = false;

  get gqlImage () {
    return graphQLImage(this.image);
  }

  get lqip () {
    return this.gqlImage?.lqip;
  }

  get lqipUrl () {
    return this.lqip?.url;
  }

  get src () {
    return this.lqipUrl || this.gqlImage?.variants?.[0].url;
  }

  get srcset () {
    return this.gqlImage?.variants
      .map(image => `${image.url} ${image.width}w`)
      .join(', ');
  }

  get dominantColor () {
    return this.gqlImage?.metadata.dominantColor;
  }

  get cssStyle () {
    let blur = {};
    if (!this.loaded)
      blur = {
        filter: 'blur(5px)',
        transform: 'scale(1.05)',
      };

    return {
      backgroundColor: this.dominantColor,
      backgroundImage: this.lqipUrl ? `url('${this.lqipUrl}')` : null,
      backgroundSize: 'cover',
      ...blur,
    };
  }

  // Remount image in DOM to trigger new lazyload
  // https://github.com/significantbit/ostgotateatern-web-frontend/issues/71
  @Watch('image')
  onImageChange () {
    this.resetImage = true;
    this.loaded = false;

    this.$nextTick(() => {
      this.resetImage = false;
    });
  }

  @Watch('loaded')
  @Watch('load')
  onLoadStateChanged () {
    if (this.loaded)
      this.$emit('load');
  }
}
