




















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class Input extends Vue {
  @Prop({ type: String, default: 'text' })
  type!: string;

  @Prop({ type: String, required: true })
  label!: string;

  @Prop(Boolean)
  disabled!: boolean;

  @Prop(String)
  hint!: string;

  @Prop(String)
  error!: string;

  value = '';

  get id () {
    return `input_${this._uid}`;
  }

  get listeners () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { change, ...rest } = this.$listeners;
    return rest;
  }
}
