
export default {
  inheritAttrs: false,
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  render (h) {
    const to = this.to;

    if (!to)
      return h(this.tag, this.$slots.default);

    if (to && (typeof to !== 'string' || to.startsWith('/')))
      return h(
        'nuxt-link',
        {
          props: {
            ...this.$attrs,
            to,
          },
          class: 'nle-link',
        },
        this.$slots.default,
      );
    else
      return h(
        'a',
        {
          attrs: {
            ...this.$attrs,
            href: to,
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          class: 'nle-link',
        },
        this.$slots.default,
      );
  },
};
